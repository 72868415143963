import React, { FC, useEffect, useRef, useState } from "react";
import {
  AlertNotification,
  Button,
  H3,
  LabeledInput,
  Popup,
  PopupHeader,
  SkubotSpinner,
  XIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  selectIsLoading,
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../redux/isLoading";
import { createUpdateTemplate } from "../../../actions/shop";

interface RenameTemplatePopupProps {
  public_view_template_id: string;
  public_view_template_title: string;
  templateNames: string[];
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RenameTemplatePopup: FC<RenameTemplatePopupProps> = ({
  public_view_template_id,
  public_view_template_title,
  templateNames,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const [inputTemplateName, setInputTemplateName] = useState<string>(
    public_view_template_title
  );
  const onPopupClose = () => dispatch(closePopup());

  const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTemplateName(e.target.value);
  };

  const error =
    inputTemplateName.trim() &&
    inputTemplateName.trim() !== public_view_template_title &&
    templateNames.find(
      (t) =>
        t.localeCompare(inputTemplateName.trim(), undefined, {
          sensitivity: "base",
          ignorePunctuation: true,
        }) === 0
    )
      ? "Template with the same name already exists.  Please choose a different name."
      : "";

  const handleSaveTemplate = async () => {
    dispatch(setIsLoadingTrue());
    await dispatch(
      createUpdateTemplate(public_view_template_id, {
        public_view_template_title: inputTemplateName.trim(),
      })
    );
    dispatch(setIsLoadingFalse());
    dispatch(closePopup());
  };

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 560, height: error ? 289 : 208, borderRadius: 10 }}
      header={
        <PopupHeader
          style={{ padding: 0, alignItems: "center", marginBottom: 16 }}
        >
          <H3 style={{ margin: "0px" }} className="title">
            Rename Template
          </H3>
          <XIcon
            style={{ cursor: "pointer" }}
            size="large"
            onClick={() => onPopupClose()}
          />
        </PopupHeader>
      }
    >
      <StyledContainer>
        <LabeledInput
          value={inputTemplateName}
          placeholder={"Type Here"}
          onChange={handleTemplateNameChange}
          label="Template Name"
        />
        {error && (
          <AlertNotification
            alertType="error"
            style={{ height: 60, alignItems: "center", marginBottom: 16 }}
          >
            {error}
          </AlertNotification>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 16,
          }}
        >
          <Button
            variant="secondary"
            onClick={onPopupClose}
            size="medium"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant={"primary"}
            disabled={
              !inputTemplateName.trim() ||
              isLoading ||
              public_view_template_title === inputTemplateName ||
              error
            }
            onClick={handleSaveTemplate}
            size="medium"
            style={{ width: 135 }}
          >
            {isLoading ? (
              <SkubotSpinner
                size="button"
                skubot={false}
                style={{ margin: "auto" }}
                color="white"
              />
            ) : (
              "Rename"
            )}
          </Button>
        </div>
      </StyledContainer>
    </Popup>
  );
};

export default RenameTemplatePopup;
